import React from 'react'
import {
  Container,
  Header,
  Segment
}
  from 'semantic-ui-react'

const NotFound = () => (
  <Segment className='notfound-content' vertical>
    <Container text>
      <Header as='h1'>
        Content not found.
      </Header>
      <p>&nbsp;</p>
      <p>
        Try using the navigation links at the top of the page.
      </p>
    </Container>
  </Segment>
)

export default NotFound
