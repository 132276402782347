import React from 'react'
import {
  Container,
  Segment,
} from 'semantic-ui-react'
import EmailComposer from './EmailComposer.jsx'

/* eslint-disable react/no-multi-comp */
const EmailPage = () => (
  <Segment vertical>
    <Container>
        <EmailComposer></EmailComposer>
    </Container>
  </Segment>
)

export default EmailPage
