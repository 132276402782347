import React, { createRef } from 'react'
import {
  Button,
  Form,
  Grid,
  Icon,
  Input,
  TextArea,
}
  from 'semantic-ui-react'
import { CSSTransition } from 'react-transition-group'

class EmailComposer extends React.Component {
  inputRef = createRef()
  state = { visible: true }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  resetForm = () => {
    this.setState({ to: '', subject: '', body: '', visible: true })
    this.inputRef.current.focus()
  }

  handleSubmit = () => this.setState({ visible: false });

  render() {
    const { body, subject, to, visible } = this.state

    return (
      <CSSTransition
        in={visible}
        timeout={1800}
        classNames="composer"
        onExited={() => this.resetForm()}
      >
        <Form className='email-composer' onSubmit={this.handleSubmit}>
          <Grid columns={16}>
            <Grid.Row></Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='right' width={1}></Grid.Column>
              <Grid.Column width={14}>
                <Input
                  placeholder='To:'
                  name='to'
                  value={to}
                  onChange={this.handleChange}
                  autoFocus
                  ref={this.inputRef}
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='right' width={1}>
              </Grid.Column>
              <Grid.Column width={14}>
                <Input
                  placeholder='Subject:'
                  name='subject'
                  value={subject}
                  onChange={this.handleChange}
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={14}>
                <TextArea
                  className="email-body"
                  placeholder=''
                  name='body'
                  value={body}
                  onChange={this.handleChange}
                  autoComplete='off'
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={14}>
                <Button type='submit' primary>
                  Send
                <Icon name='right arrow' />
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        </Form>
      </CSSTransition>
    )
  }
}

export default EmailComposer
