import PropTypes from 'prop-types'
import React from 'react'
import {
  Button,
  Container,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

/* eslint-disable react/no-multi-comp */
const HomepageHeading = ({ mobile }) => (
  <Segment
    className='homepage-heading'
    inverted
    textAlign='center'
    vertical
  >
    <Container text>
      <Header
        as='h1'
        className='homepage'
        content='Unsent Page'
        inverted
      />
      <Header
        as='h2'
        className='homepage'
        content='Get it out. Let it go.'
        inverted
      />
      <Button as={NavLink} to='/letter' primary size='huge'>
        Get Started
      <Icon name='right arrow' />
      </Button>
    </Container>
  </Segment>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const Homepage = () => (
  <>
    <HomepageHeading />
    <Segment className='homepage-content' vertical>
      <Container text>
        <Header as='h3'>
          Express yourself, for yourself.
        </Header>
        <p>
          Sometimes you have something to say. Your thoughts need to come out,
           but sending that letter or email would not help the situation. Unsent
           Page is here to let you express yourself.
        </p>
        <p>
          Write that email; write the letter - then watch it disappear.
        </p>
      </Container>
    </Segment>
  </>
)

export default Homepage
