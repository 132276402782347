import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import EmailPage from './components/EmailPage.jsx'
import Footer from './components/Footer.jsx'
import LetterPage from './components/LetterPage.jsx'
import HomePageLayout from './components/Homepage.jsx'
import ResponsiveContainer from './components/ResponsiveContainer'
import AboutPage from './components/AboutPage'
import NotFoundPage from './components/NotFoundPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NotFound from './components/NotFoundPage';


function App() {
  return (
    <div className="App">
      <Router>
        <ResponsiveContainer>
          <Switch>
            <Route path='/email'>
              <EmailPage></EmailPage>
            </Route>
            <Route path='/letter'>
              <LetterPage></LetterPage>
            </Route>
            <Route path='/about'>
              <AboutPage></AboutPage>
            </Route>
            <Route exact path='/'>
              <HomePageLayout></HomePageLayout>
            </Route>
            <Route>
              <NotFoundPage></NotFoundPage>
            </Route>
          </Switch>
          <Footer></Footer>
        </ResponsiveContainer>
      </Router>
    </div>
  );
}

export default App;
