import React from 'react'
import {
  Container,
  Segment,
} from 'semantic-ui-react'
import LetterComposer from './LetterComposer.jsx'

/* eslint-disable react/no-multi-comp */
const LetterPage = () => (
  <Segment vertical>
    <Container>
        <LetterComposer></LetterComposer>
    </Container>
  </Segment>
)

export default LetterPage
