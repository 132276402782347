import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'

const Footer = () => (
  <Segment className='footer' inverted vertical>
    <Container>
      <Header as='h4' inverted>
        Do we collect your data?
      </Header>
      <p>
        Nope. Your content never leaves the browser.
      </p>
    </Container>
  </Segment>
)

export default Footer
