import React from 'react'
import {
  Container,
  Header,
  Segment
}
  from 'semantic-ui-react'

const About = () => (
  <Segment className='about-content' vertical>
    <Container text>
      <Header as='h3'>
        What is happening here?
      </Header>
      <p>&nbsp;</p>
      <p>
        This site is a digital version of the idea of writing a letter to
        someone and then burning it. You are able to get your thoughts out of
        your head, see them, organize them, and then watch it disappear.
      </p>
      <p>
        Nothing that you type here is saved or tracked. It never leaves your
        web browser.
      </p>
    </Container>
  </Segment>
)

export default About
